@font-face {
  font-family: "DubaiRegular"; /*Can be any text*/
  src: local("DubaiRegular"),
    url("../public/fonts/CORBEL.TTF") format("truetype");
  font-display: swap;
}

.App {
  text-align: center;
  font-family: DubaiRegular;
}

html {
  scroll-behavior: smooth;
}

/*BASIC*/

.p-t-56 {
  padding-top: 56px;
}

.p-16 {
  padding: 16px 8px 16px 8px;
}

.w-80-p {
  /* width: 80vw; */
  margin-right: 5%;
  margin-left: 5%;
}

.nav-logo {
  height: 29px;
}

.m-b-0 {
  margin-bottom: 0;
}

.display-block {
  display: block !important;
}

.m-r-20 {
  margin-right: 20px;
}
.color-grey {
  color: #9c9797 !important;
  font-weight: initial;
}

.p-t-60 {
  padding-top: 60px;
}

.color-black {
  color: black;
}

.font-size-25 {
  font-size: 25px;
}

.min-height-25 {
  min-height: 25vh;
}

.no-underline {
  text-decoration: none;
}

/*NAVBAR*/
.navbar {
  z-index: 99999;
  background-color: #ffffff; /*whichever you want*/
  opacity: 0.95; /*0.5 determines transparency value*/
  filter: (opacity=50);
}

.j-content-end {
  justify-content: right;
}

/*PROJECTS*/
.scroll-horizontal {
  overflow-x: auto;
  white-space: nowrap;
  height: 100%; /* nastavte podľa potreby */
  width: 100%;
  scrollbar-width: none; /* skryje scrollbar pre Firefox */
  -ms-overflow-style: none; /* skryje scrollbar pre Edge */
}

.scroll-horizontal::-webkit-scrollbar {
  display: none;
}

.scroll-horizontal p {
  display: inline-block;
  vertical-align: top;
}
.scroll-horizontal div {
  display: inline-block;
  vertical-align: top;
  max-height: 100%;
}
.scroll-horizontal img {
  display: inline-block;
  position: relative;
  z-index: -1;
  vertical-align: top;
  max-height: 100%;
}
.container-project {
  display: flex; /* nastavíme ako flexbox kontajner */
  justify-content: center; /* vycentrujeme elementy na horizontálnej osi */
  align-items: center; /* vycentrujeme elementy na vertikálnej osi */
  height: 30vh; /* nastavíme výšku na 100% pre kontajner */
  margin-bottom: 50px;
  cursor: pointer;
}

.container-project.active {
  /* transform: scale(1.2); */
  height: 60vh;
  transition: transform 0.2s;
}
.project-info {
  max-width: 23vw !important;
  min-width: 290px;
  max-height: 100%;
}

@media (max-width: 500px) {
  .project-info {
    max-width: 55vw !important;
  }
  .container-project {
    height: 20vh; /* nastavíme výšku na 100% pre kontajner */
  }
}
@media (max-width: 600px) {
  .container-project {
    height: 20vh; /* nastavíme výšku na 100% pre kontajner */
    margin-bottom: 5%;
    font-size: 10px;
  }

  .container-project.active {
    height: 34vh;
    min-height: 192px;
  }

  .active .project-info {
    font-size: 13px;
  }
  .project-info {
    max-height: 100% !important;
  }
}
@media (max-width: 700px) {
  .active .project-info {
    font-size: 13px;
  }
  .project-info {
    max-height: 100% !important;
    max-width: 240px !important;
  }
}

.name-var {
  color: #dedbdb;
}
.value-var {
  color: black;
}

.hide-description-mobile {
  display: none !important;
}

.image-one-size {
  width: 36%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .image-one-size {
    width: 50%;
    max-height: 100%;
  }
  /* .img-gallery {
    max-height: 100%;
  } */
}

/*HOME*/

.logo {
  width: 200px;
}

/*FOOTER*/
footer {
  border-top: 1px solid #dee2e6;
  padding-top: 1.5rem;
  margin-top: 80px;
}

/* Style for the footer content */
footer h4 {
  color: #333;
  margin-top: 0;
  margin-bottom: 5px;
}

footer p {
  color: #777;
  margin-bottom: 20px;
}

footer a {
  color: #777;
}

/* Style for the copyright section */
footer .bg-secondary {
  margin-top: 50px;
  padding: 10px 0;
}

footer p.m-0 {
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.copyright {
  background-color: #7d7d7d33 !important;
  color: black !important;
  margin-top: 50px;
  padding: 10px 0;
}

/*ABOUT*/
.about-view {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  /* height: 100vh; */
}

.about-p {
  width: 60vw;
  margin: 0 auto;
  text-align: center;
}

.container {
  position: relative;
  max-width: 100%;
}

.container .about-img {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
}

.text-container-matka {
  position: absolute;
  top: 17%;
  left: 74%;
  transform: translate(-50%, -50%);
}

.text-container-peto {
  position: absolute;
  top: 75%;
  left: 27%;
  transform: translate(-50%, -50%);
}

.text {
  font-size: 24px;
  color: white;
}

@media (max-width: 500px) {
  .about-p {
    width: 80vw;
    margin: 0 auto;
    text-align: center;
  }
  .text-container-peto {
    font-size: x-small;
  }
  .text-container-matka {
    font-size: x-small;
  }
  .text {
    font-size: large;
  }
}

@media (max-width: 920px) {
  .about-p {
    width: 80vw;
    margin: 0 auto;
  }
}

@media (min-width: 920px) {
  .text-container-matka {
    top: 20%;
  }
}
