body {
  margin: 0;
}
h1,
p,
h2,
h3,
h4,
strong,
a {
  color: black !important;
}

.white-color {
  color: white !important;
}
