.resizing-circle {
  display: inline-block;
  font-size: 30px;
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  transform: scale(0.2);
  transition: transform 0.5s ease-in-out;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.circle.pulsating {
  margin-left: 2px;
  margin-bottom: -2px;
  animation: pulsate 1s linear infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0.2);
  }
}
